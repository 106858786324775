jQuery(document).ready(function() {
  if (jQuery('#modal-window').length > 0 && checkCookie()) {
    var saw_popup = readCookie('saw_popup');
    if (saw_popup !== 'yes') {
      setTimeout(function() {
        jQuery('#modal-window').modal();
      }, 300);
      createCookie('saw_popup', 'yes', .25);
    }
  }
});