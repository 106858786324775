var autocomplete, autocomplete_end, placeSearch;
var map = null;
var start_marker, end_marker;
var added_icon, default_icon;

// Itinerary view vars
var vi_start, vi_start_lat, vi_start_lng;
var vi_end, vi_end_lat, vi_end_lng;
var stop_list = [];
var $markers;
var directionsService;
var directionsDisplay;


added_icon = {
  url: vars.theme_url + '/assets/images/pin-green.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};
winery_icon = {
  url: vars.theme_url + '/assets/images/pin-purple.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};
cidery_icon = {
  url: vars.theme_url + '/assets/images/pin-red.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};
distillery_icon = {
  url: vars.theme_url + '/assets/images/pin-gray.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};
other_icon = {
  url: vars.theme_url + '/assets/images/pin-green2.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};
default_icon = {
  url: vars.theme_url + '/assets/images/pin-green.png', // url
  scaledSize: new google.maps.Size(20, 32), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(10, 32), // anchor
  labelOrigin: new google.maps.Point(10, 12),
  label: {
    text: '',
    color: 'white'
  }
};


/*
 * Add a marker to the selected Google Map
 *
 * @return n/a
 */
function add_marker($marker, map) {

  // var
  var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

  var icon = '';
  var label = '';
  var id = $marker.attr('data-id');

  // If selected, use the selected icon
  if ($marker.attr('data-selected') === 'y') {
    icon = added_icon;
    label = {
      text: $marker.attr('data-sort'),
      color: 'white'
    };
  } else if ($marker.attr('data-type') == 'brewery') {
    icon = default_icon;
  } else if ($marker.attr('data-type') == 'winery') {
    icon = winery_icon;
  } else if ($marker.attr('data-type') == 'distillery') {
    icon = distillery_icon;
  } else if ($marker.attr('data-type') == 'cidery') {
    icon = cidery_icon;
  } else if ($marker.attr('data-type') == 'other') {
    icon = other_icon;
  }
  // User the regular icon
  else {
    icon = default_icon;
  }

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: icon,
    id: id,
    //label: label,
    //sort: $marker.attr('data-sort'),
    address: $marker.attr('data-addr'),
    //name: $marker.attr('data-name')
  });

  // add to array
  var result = map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($marker.html()) {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
      maxWidth: 400
    });
    marker.infowindow = infowindow;

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {
      // Close all other markers
      jQuery.each(map.markers, function(i, marker) {
        marker.infowindow.close();
      });
      infowindow.open(map, marker);
      //jQuery('header').addClass('headroom--unpinned');
    });
  }
  marker.setVisible(false);

  setTimeout(function() {
    marker.setVisible(true);
    marker.setAnimation(google.maps.Animation.DROP);
  }, 300);

}


/*
 * Center a map
 *
 * @return n/a
 */
function center_map(map) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  jQuery.each(map.markers, function(i, marker) {
    var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length === 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
    map.setZoom(12);
  } else if (map.markers.length > 1) {
    // fit to bounds
    map.fitBounds(bounds);
  }

}


/*
 * Render a google map on a jquery element
 *
 * @return n/a
 */
function new_map($el) {

  var $markers = jQuery('.map-markers').find('.marker');
  //var $markers = $el.find('.marker');

  var args = {
    zoom: 16,
    scrollwheel: false,
    center: new google.maps.LatLng(42.710759, -76.6857287),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    streetViewControl: false,
    mapTypeControl: false
  };

  // create map
  var n_map = new google.maps.Map($el[0], args);

  // add a markers reference
  n_map.markers = [];

  // add markers
  $markers.each(function() {
    add_marker(jQuery(this), n_map);
  });

  // center map
  //center_map(n_map);

  // return
  return n_map;
}



function initialize() {

  // Initialize main map.
  if (jQuery('#acf-map').length > 0) {

    // create map
    map = new_map(jQuery('#acf-map'));


    // Remove loader when map is loaded
    google.maps.event.addListenerOnce(map, 'idle', function() {
      jQuery('.loader').removeClass('inplace');
    });

    // Add listener to close info windows when the map is clicked.
    google.maps.event.addListener(map, "click", function(event) {
      //jQuery('header').addClass('headroom--unpinned');

      jQuery.each(map.markers, function(i, marker) {
        marker.infowindow.close();
      });
    });

    center_map(map);

    //
    //jQuery('#acf-map').on('click', function() {
    //  console.log('unpined');
    //  jQuery('header').addClass('headroom--unpinned');
    //})

    //google.maps.event.addListener.addListener(map, 'click', function() {

    //});

  }

  // Initialize start location map.
  if (jQuery('#start-location-map').length > 0) {
    start_map = new_map(jQuery('#start-location-map'));
    start_marker = new google.maps.Marker({
      map: start_map,
      anchorPoint: new google.maps.Point(0, -29)
    });

    // Set map position based on viewer location
    /*
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        start_map.setCenter(geolocation);
      });
    }
    */

    start_map.setZoom(10);
  }

  // Initialize end location map
  if (jQuery('#end-location-map').length > 0) {
    end_map = new_map(jQuery('#end-location-map'));
    end_marker = new google.maps.Marker({
      map: end_map,
      anchorPoint: new google.maps.Point(0, -29)
    });


    // Set map position based on viewer location
    /*
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        end_map.setCenter(geolocation);
      });
    }
    */

    end_map.setZoom(10);
  }

}

google.maps.event.addDomListener(window, 'load', initialize);
jQuery(document).ready(function() {});

/*
 * Update sorting on the map.
 *
 * @return n/a
 */
function updateMapSorting() {
  var found;
  var label = {};

  jQuery.each(map.markers, function(i, marker) {
    found = false;

    jQuery('ol.itinerary-ordered-list li').each(function(index, value) {
      if (jQuery(this).attr('data-id') == marker.id) {

        // This marker should be lit the heck up.
        marker.setIcon(added_icon);

        marker.setLabel({
          text: jQuery(this).attr('data-sort_order'),
          color: 'white'
        });

        marker.sort = jQuery(this).attr('data-sort_order');

        found = true;
      }
    });

    if (!found) {
      marker.setIcon(default_icon);
      marker.setLabel(' ');
    }
  });

  // Update directions if they are viewing the itinerary.
  if (jQuery('.view-map').length > 0) {
    setTimeout(function() {
      refresh_itinerary_view();
    }, 200);
    console.log('Refreshing itinerary directions');
  }
}

/*
 * Fetch itinerary from database and update.
 *
 * @return n/a
 */
function update_itinerary(post_id) {

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "get_itinerary_list",
      post_id: post_id
    },

    success: function(response) {
      if (response.success === "true") {
        jQuery('ol.itinerary-ordered-list').html(response.list_items);
      } else {
        console.log('Could not update list ' + response.list_items);
      }

      setTimeout(function() {
        updateMapSorting();
        jQuery('.loader').removeClass('inplace');
      }, 10);
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);
      jQuery('.loader').removeClass('inplace');
    }
  });

}

/*
 * Save itinerary title and update it on DOM
 *
 * @return n/a
 */
function update_itinerary_title(post_id, title) {

  jQuery('.loader').addClass('inplace');

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "update_itinerary_title",
      post_id: post_id,
      title: title
    },

    success: function(response) {
      if (response.success === "true") {
        jQuery('.edit-name').html(title);

        setTimeout(function() {
          jQuery('.modal').removeClass('display');
        }, 200);
        jQuery('.modal').removeClass('inplace');

      } else {
        console.log('Could not update title: ' + response.msg);
      }

      jQuery('.loader').removeClass('inplace');
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);
      jQuery('.loader').removeClass('inplace');
    }
  });
}

/*
 * Update the database with the new sort order just set by user.
 *
 * @return n/a
 */
function itinerary_update_sorting(post_id) {
  // Loader.
  jQuery('.loader').addClass('inplace');

  var i = 0;
  var order = [];

  jQuery(".sortable li").each(function(index, element) {
    order[index] = jQuery(this).attr("data-sort_order");
  });

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "update_itinerary_sorting",
      post_id: post_id,
      order: order
    },

    success: function(response) {
      update_itinerary(post_id);
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);
      jQuery('.loader').removeClass('inplace');
    }
  });
}

/*
 * Add winery to itinerary
 *
 * @return n/a
 */
function add_to_itinerary(post_id, stop_post_id) {

  // Loader.
  jQuery('.loader').addClass('inplace');

  console.log(vars.ajaxurl);
  console.log(stop_post_id);

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "add_to_itinerary",
      post_id: post_id,
      stop_post_id: stop_post_id
    },

    success: function(response) {
      if (response.success === "true") {

      } else {
        console.log('response is not success: ' + response);
      }

      update_itinerary(post_id);

      // Close all other markers
      jQuery.each(map.markers, function(i, marker) {
        marker.infowindow.close();
      });
    },

    error: function(jqXHR, status, error) {
      console.log('ERROR: ' + error);
      jQuery('.loader').removeClass('inplace');
    }
  });
}

/*
 * Delete a winery from the itinerary
 *
 * @return n/a
 */
function delete_from_itinerary(post_id, sort, stop_post_id) {

  // Loader.
  jQuery('.loader').addClass('inplace');

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "delete_from_itinerary",
      post_id: post_id,
      sort: sort
    },

    success: function(response) {
      if (response.success === "true") {

      } else {
        console.log('response is not success: ' + response.msg);
      }
      update_itinerary(post_id);
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);
      jQuery('.loader').removeClass('inplace');
    }
  });
}

/*
 * Save start location
 *
 * @return n/a
 */
function save_itinerary_start() {

  // Loader.
  jQuery('.loader').addClass('inplace');

  var post_id = jQuery('#start_location .post_id').val();
  var address = encodeURIComponent(jQuery('#start_location .address').val());
  var loc;

  var place = autocomplete.getPlace();
  if (!place.geometry) {
    // User entered the name of a Place that was not suggested and
    // pressed the Enter key, or the Place Details request failed.
    window.alert("No details available for input: '" + place.name + "'");
    return;
  }

  var lat = place.geometry.location.lat();
  var lng = place.geometry.location.lng();

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "save_itinerary_start",
      post_id: post_id,
      lat: lat,
      lng: lng,
      address: address
    },

    success: function(response) {
      if (response.success === "true") {
        jQuery('#start-addr-display').html(jQuery('#start_location .address').val());
      } else {
        console.log('response is not success: ' + response.msg);
      }

      // Remove loader
      jQuery('.loader').removeClass('inplace');

      // Update link anchor
      jQuery('.edit-start-address').html('Edit');

      // Hide window
      setTimeout(function() {
        jQuery('.modal').removeClass('display');
      }, 200);
      jQuery('.modal').removeClass('inplace');
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);

      // Remove loader
      jQuery('.loader').removeClass('inplace');
      jQuery('#edit-start-location').removeClass('inplace');
    }
  });
}

/*
 * Save ending point
 *
 * @return n/a
 */
function save_itinerary_end() {

  // Loader.
  jQuery('.loader').addClass('inplace');

  var post_id = jQuery('#end_location .post_id').val();
  var address = encodeURIComponent(jQuery('#end_location .address').val());
  //address = '';
  var loc;

  var place = autocomplete_end.getPlace();
  if (!place.geometry) {
    // User entered the name of a Place that was not suggested and
    // pressed the Enter key, or the Place Details request failed.
    window.alert("No details available for input: '" + place.name + "'");
    return;
  }

  var lat = place.geometry.location.lat();
  var lng = place.geometry.location.lng();

  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: myAjax.ajaxurl,
    data: {
      action: "save_itinerary_end",
      post_id: post_id,
      lat: lat,
      lng: lng,
      address: address
    },

    success: function(response) {
      if (response.success === "true") {
        jQuery('#end-addr-display').html(jQuery('#end_location .address').val());
      } else {
        console.log('response is not success: ' + response.msg);
      }

      // Remove loader
      jQuery('.loader').removeClass('inplace');

      // Update link anchor
      jQuery('.edit-end-address').html('Edit');

      // Remove window
      setTimeout(function() {
        jQuery('.modal').removeClass('display');
      }, 200);
      jQuery('.modal').removeClass('inplace');
    },

    error: function(errorThrown) {
      console.log('ERROR: ' + errorThrown);

      // Remove loader
      jQuery('.loader').removeClass('inplace');
      jQuery('#edit-start-location').removeClass('inplace');
    }
  });
}

/*
 * Init the start and ending point autocomplete functionality via Google Maps API
 *
 * @return n/a
 */
function initAutocomplete() {

  // Create the autocomplete object, restricting the search to geographical
  // location types.
  if (jQuery('#start_autocomplete').length > 0) {
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */
      (document.getElementById('start_autocomplete')), {
        types: ['geocode']
      });

    autocomplete.addListener('place_changed', function() {
      start_marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        start_map.fitBounds(place.geometry.viewport);
      } else {
        start_map.setCenter(place.geometry.location);
        start_map.setZoom(17); // Why 17? Because it looks good.
      }
      start_marker.setPosition(place.geometry.location);
      start_marker.setVisible(true);
    });
  }

  // Create the autocomplete object, restricting the search to geographical
  // location types.
  if (jQuery('#end_autocomplete').length > 0) {
    autocomplete_end = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */
      (document.getElementById('end_autocomplete')), {
        types: ['geocode']
      });

    autocomplete_end.addListener('place_changed', function() {
      end_marker.setVisible(false);
      var place = autocomplete_end.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        end_map.fitBounds(place.geometry.viewport);
      } else {
        end_map.setCenter(place.geometry.location);
        end_map.setZoom(17); // Why 17? Because it looks good.
      }
      end_marker.setPosition(place.geometry.location);
      end_marker.setVisible(true);
    });
  }
}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      var circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

/*
 * Given a response from Google Directions API, build an HTML string to ouput the directions.
 *
 * @return n/a
 */
function setDirectionsData(response, sorted_stops) {

  var directions = response.routes[0];
  var html = '';
  var steps;

  // Calculate the total distance and duration.
  var total_distance = 0;
  var total_duration = 0;
  var legs = directions.legs;
  for (var i = 0; i < legs.length; ++i) {
    total_distance += legs[i].distance.value;
    total_duration += legs[i].duration.value;
  }

  // Calculate the hours and minutes of travel time.
  var hours = Math.floor(total_duration / 3600);
  total_duration = total_duration - hours * 3600;
  var minutes = Math.floor(total_duration / 60);

  // Build travel time string.
  var final_time = '';
  if (hours > 0) {
    final_time += hours + ' hours ';
  }
  if (minutes > 0) {
    final_time += minutes + ' minutes';
  }

  jQuery('.duration').text('Travel time: ' + final_time);

  if (directions.legs) {
    for (i = 0; i < directions.legs.length; i++) {
      html += '<div class="distance">' + directions.legs[i].distance.text + '</div>';
      html += '<div class="details"><h3><b>' + (i + 1) + '.</b> ' + sorted_stops[i + 1].name + ' (' + directions.legs[i].duration.text + ')</h3>';
      html += '<p class="address">' + directions.legs[i].end_address + '</p></div>';

      steps = directions.legs[i].steps;

      html += '<div class="expander">';
      html += '<div class="trigger">Show directions +</div>';
      html += '<ol class="the-details">';
      for (j = 0; j < steps.length; j++) {
        html += '<li><span>' + steps[j].distance.text + '</span>' + steps[j].instructions + '</li>';
      }
      html += '</ol>';
      html += '</div>';
    }
  }

  jQuery('.directions').html(html);
}

/*
 * Event listeners
 */
jQuery(document).ready(function() {

  // Adding a winery event listener
  jQuery(document).on("click", '.add-winery', function(e) {
    e.preventDefault();
    var post_id = jQuery(this).attr("data-post_id");
    var stop_post_id = jQuery(this).attr("data-stop_post_id");
    add_to_itinerary(post_id, stop_post_id);
    return false;
  });

  // Delete a winery event listener
  jQuery(document).on("click", 'ol.itinerary-ordered-list li span', function(e) {
    e.preventDefault();
    var post_id = jQuery(this).parent().parent().attr("data-post_id");
    var sort = jQuery(this).parent().attr("data-sort_order");
    var stop_post_id = jQuery(this).parent().attr("data-id");
    delete_from_itinerary(post_id, sort, stop_post_id);
    return false;
  });

  // Update the itinerary title
  jQuery('#update_title').submit(function() {
    var post_id = jQuery(this).find('input[name="post_id"]').val();
    var title = jQuery(this).find('input[name="title"]').val();
    update_itinerary_title(post_id, title);
    return false;
  });

  // Save start location
  jQuery('#start_location').submit(function() {
    save_itinerary_start();
    return false;
  });

  // Save end location
  jQuery('#end_location').submit(function() {
    save_itinerary_end();
    return false;
  });

  // Sorting event listener
  if (jQuery(".sortable").length > 0) {
    jQuery(function() {
      jQuery(".sortable").sortable({
        update: function(event, ui) {
          var post_id = jQuery(this).attr("data-post_id");
          itinerary_update_sorting(post_id);
        }
      });
    });
  }

  // Show the start address edit window
  jQuery('.edit-start-address').click(function() {
    setTimeout(function() {
      jQuery('#edit-start-location').addClass('display');
      google.maps.event.trigger(start_map, 'resize');
    }, 200);
    jQuery('#edit-start-location').addClass('inplace');
    jQuery('#start_autocomplete').focus();
    return false;
  });

  // Show the end address edit window
  jQuery('.edit-end-address').click(function() {
    setTimeout(function() {
      jQuery('#edit-end-location').addClass('display');
      google.maps.event.trigger(end_map, 'resize');
    }, 200);
    jQuery('#edit-end-location').addClass('inplace');
    jQuery('#end_autocomplete').focus();
    return false;
  });

  // Show the itinerary name edit form.
  jQuery('.edit-name').click(function() {
    setTimeout(function() {
      jQuery('.update-title').addClass('display');
    }, 200);
    jQuery('.update-title').addClass('inplace');
    return false;
  });

  // Close modal window event listener
  jQuery('.modal a.close').click(function(event) {
    setTimeout(function() {
      jQuery('.modal').removeClass('display');
    }, 200);
    jQuery('.modal').removeClass('inplace');
    return false;
  });

  // Show or hide directions event listener
  jQuery(document).on("click", ".expander .trigger", function() {
    if (jQuery(this).parents('.expander').find('.the-details').css('display') === 'none') {
      jQuery(this).parents('.expander').find('.the-details').show();
      //jQuery(this).hide();
      jQuery(this).html('Hide directions -');
    } else {
      jQuery(this).parents('.expander').find('.the-details').hide();
      //jQuery(this).hide();
      jQuery(this).html('Show directions +');
    }
  });

  // Toggle all direcitons
  jQuery(document).on("click", ".toggle-all", function() {
    if (jQuery('.the-details').css('display') === 'none') {
      jQuery('.the-details').show();
      jQuery('.trigger').html('Hide directions -');
      jQuery(this).html('Hide directions');
    } else {
      jQuery('.the-details').hide();
      jQuery('.trigger').html('Show directions +');
      jQuery(this).html('Show directions');
    }
  });
});







/*
 * Initialize the itinerary view with markers, etc.
 *
 * @return n/a
 */
function initialize_itinerary_view() {

  $markers = jQuery('#map').find('.marker');

  map = new_map(jQuery('#map'));

  // Create start marker
  var latlng_start = new google.maps.LatLng(vi_start_lat, vi_start_lng);
  var marker_end = new google.maps.Marker({
    position: latlng_start,
    map: map,
    icon: default_icon,
    label: 'S',
    address: vi_start,
  });

  // Create end marker
  var latlng_end = new google.maps.LatLng(vi_end_lat, vi_end_lng);
  var marker_end = new google.maps.Marker({
    position: latlng_end,
    map: map,
    icon: default_icon,
    label: 'E',
    address: vi_end,
  });


  directionsService = new google.maps.DirectionsService();
  directionsDisplay = new google.maps.DirectionsRenderer({
    suppressMarkers: true
  });

  directionsDisplay.setMap(map);

  // Add listener to close info windows when the map is clicked.
  google.maps.event.addListener(map, "click", function(event) {
    jQuery.each(map.markers, function(i, marker) {
      marker.infowindow.close();
    });
  });

  google.maps.event.addListenerOnce(map, 'idle', function() {
    jQuery('.loader').removeClass('inplace');
  });

  refresh_itinerary_view();

}

/*
 * Changes in sorting triggers a new API call for new directions
 *
 * @return n/a
 */
function refresh_itinerary_view() {

  // The start and end can't change at this point, just update the waypoints.
  var waypoints = [];
  var sorted_stops = [];

  // The markers have been updated with the new sorting, so build array of stops for Google.
  jQuery.each(map.markers, function(i, the_marker) {
    sorted_stops.push({
      location: the_marker.address,
      name: the_marker.name,
      sort: the_marker.sort
    });
  });

  // Sort the array by the sort value.
  sorted_stops = sorted_stops.sort(function(a, b) {
    return a.sort > b.sort;
  });

  // Build new waypoints array with info needed by Google.
  for (i = 0; i < sorted_stops.length; i++) {
    waypoints.push({
      location: sorted_stops[i].location,
      stopover: true
    });
  }


  // Build new Google directions request
  var request = {
    origin: vi_start,
    destination: vi_end,
    waypoints: waypoints,
    travelMode: google.maps.DirectionsTravelMode.DRIVING
  };

  directionsService.route(request, function(response, status) {
    if (status == google.maps.DirectionsStatus.OK) {
      directionsDisplay.setDirections(response);
      // Add the starting locaation
      sorted_stops.unshift({
        location: vi_start,
        name: 'Starting location',
      });
      // Add the ending location
      sorted_stops.push({
        location: vi_end,
        name: 'Ending location',
      });
      //
      // Update the data on the apge.
      //
      setDirectionsData(response, sorted_stops);
    }
  });
}



jQuery(document).on('facetwp-refresh', function() {

  setTimeout(function() {
    jQuery(".loader").css("display", "block");
    setTimeout(function() {
      jQuery(".loader").addClass("is_loading");
    }, 20);
  }, 0);

});

jQuery(document).on('facetwp-loaded', function() {


  if (map) {

    // Clear all of the markers.
    jQuery.each(map.markers, function(i, marker) {
      //marker.infowindow.close();
      marker.setMap(null);
    });

    // add a markers reference
    map.markers = [];

    var $markers = jQuery('.map-markers').find('.marker');

    // add markers
    if ($markers.length > 0) {
      $markers.each(function() {
        add_marker(jQuery(this), map);
      });
    }

    center_map(map);

  }


  setTimeout(function() {
    jQuery(".loader").removeClass("is_loading");
    setTimeout(function() {
      jQuery(".loader").css("display", "none");
    }, 20);
  }, 0);

  hide_map_filters();

});





function show_map_filters() {
  jQuery(".map-filters").addClass("open");
  jQuery(".wrapper").addClass("map-filters-open");
}

function hide_map_filters() {
  jQuery(".map-filters").removeClass("open");
  jQuery(".wrapper").removeClass("map-filters-open");
}



/* Show and hide the mobile menu. */
jQuery(".map-filters-icon").unbind("click").bind("click", function(e) {

  // Slide the mobile menu out.
  if (!jQuery(".map-filters").hasClass('open')) {
    show_map_filters();
  }
  // Slide the mobile menu out.
  else {
    hide_map_filters();
  }

});